/* Global CSS styles */
html, body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
}

.App {
  overflow-x: hidden; /* Prevent horizontal scrolling within the App */
}

.container {
  width: 100%; /* Full width on all screens */
  max-width: 100%; /* Ensure it doesn't exceed the viewport width */
  margin: 0 auto; /* Center the container */
  padding: 0 1rem; /* Add some padding */
  box-sizing: border-box; /* Include padding in the width calculation */
}
